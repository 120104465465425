<template>
  <div class="preloader" :class="{'is-loaded': load}">
    <div class="pl-l"></div>
    <div class="pl-r"></div>
    <div class="pl-line">
      <div></div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['load'],
    name: 'loader',
  }
</script>
<style lang="scss">
$ease: cubic-bezier(0.785, 0.135, 0.150, 0.860);

$black: rgba(0,0,0,1);
$bgLine: $black;
$colorLine: #fff;

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;
  transition: opacity .1s;
  transition-delay: 3s;
  &.is-loaded {
    pointer-events: none;
    background: rgba(0,0,0,0);
    opacity: 0;
  }
}

.pl-line {
  position: absolute;
  width: 1px;
  height: 150%;
  background: $bgLine;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(20deg);
  transition: opacity 1s ease-out;
  .is-loaded &{
    opacity: 0;
  }
  > div {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $colorLine;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    animation: pulse 2s infinite;
    will-change: transform;
    animation-timing-function: $ease;
  }
}

.pl-l, .pl-r {
  position: absolute;
  top: 0;
  width: 70%;
  height: 100%;
  transition: transform 1s $ease;
  will-change: transform;
  background: $black;
}

.pl-l {
  left: -20%;
  transform: skew(-20deg);
  .is-loaded &{
    transform: translateX(-100%) skew(-20deg);
  }
}

.pl-r {
  right: -20%;
  transform: skew(-20deg);
  .is-loaded &{
    transform: translateX(100%) skew(-20deg);
  }
}


@keyframes pulse {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
</style>
