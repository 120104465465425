<template>
  <div class="small-loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<script>
export default {
  name: 'small-loader',
}
</script>
<style lang="scss">
    .small-loader{
        position: relative;
        min-width: 100px;
        min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .loader{
            min-height: 25px;
            &>div{
                width: 25px;
                height: 25px;
            }
        }
    }
    $count: 7;
    $time: 2; //in seconds
    $size: 25;
    $color: #ffffff;


    .small-loader {
      /* size */
      width: $size + px;
      height: $size + px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      animation: rotatePreloader $time + s infinite ease-in;
    }

    @keyframes rotatePreloader {
      0% {
        transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
      }
      100% {
        transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
      }
    }

    .small-loader div {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .small-loader div:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      width: 10%;
      height: 10%;
      background-color: $color;
      transform: translateX(-50%);
      border-radius: 50%;
    }

    @for $i from 1 through $count {
      .small-loader div:nth-child(#{$i}) {
        transform: rotateZ(((360 / $count) * ($i - 1)) + deg);
        animation: rotateCircle + $i $time + s infinite linear;
        z-index: $count - $i;
      }
      @keyframes rotateCircle#{$i} {
        #{percentage(((50 / $count) * ($i - 1)) / 100)} {
          opacity: 0;
        }
        #{percentage((((50 / $count) + 0.0001) * ($i - 1)) / 100)} {
          opacity: 1;
          transform: rotateZ((0 - ((360 / $count) * ($i - 2))) + deg);
        }
        #{percentage((((50 / $count) * ($i - 0)) + 2) / 100)} {
          transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
        }
        #{percentage(((50 + ((50 / $count) * ($i - 0))) + 2) / 100)} {
          transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
        }
        100% {
          transform: rotateZ((0 - ((360 / $count) * ($count - 1))) + deg);
          opacity: 1;
        }
      }
    }
</style>
