import productCard from "../../../product-card/index.vue";
export default {
  name: 'search',
  components: {
    productCard
  },
  props: {
    list: {
      type: Array,
      default: ()=> []
    },
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
}
