import Vue from 'vue';
import {$http} from '@/utils/https';
import {
  ADD_ITEM_TO_BASKET,
  ADD_ITEM_TO_FAVORITES,
  CHECK_PROMO_CODE_LOADING,
  DECREMENT_BASKET_LIST_COUNT,
  INCREMENT_BASKET_LIST_COUNT,
  REMOVE_ITEM_FROM_BASKET,
  REMOVE_ITEM_FROM_FAVORITES,
  RESET_BASKET,
  RESET_DISCOUNT,
  SET_BASKET_EDIT_COUNT,
  SET_BASKET_LIST_COUNT,
  SET_DISCOUNT,
  SET_PROMO_CODE_IN_BASKET,
} from '../mutation-types';
import {CHECK_PROMO_CODE,} from '../action-types';

function save() {
  const parsed = JSON.stringify(state.basket);
  localStorage.setItem('products', parsed);
}
function saver() {
  const parsed = JSON.stringify(state.favorites);
  localStorage.setItem('favorites', parsed);
}

const state = {
  buyLoading: false,
  promoCode: '',
  discount: null,
  loadingPromoCode: false,
  basket: localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [],
  favorites: localStorage.getItem('favorites') ? JSON.parse(localStorage.getItem('favorites')) : [],
};

const getters = {
  showPopupRemoveItem: state => state.showPopupRemoveItem,
  removeDataItem: state => state.removeDataItem,

  promoCode: state => state.promoCode,
  discount: state => state.discount,
  buyLoading: state => state.buyLoading,
  loadingPromoCode: state => state.loadingPromoCode,
  basket: state => state.basket || [],
  favorites: state => state.favorites || [],
};

const actions = {
  [CHECK_PROMO_CODE]: async ({commit}) => {
    commit(CHECK_PROMO_CODE_LOADING, true);
    commit(RESET_DISCOUNT);
    try {
      const response = await $http.post(`v1/order/coupon`, { code: state.promoCode });

      commit(SET_DISCOUNT, response.data.data.percent);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit(CHECK_PROMO_CODE_LOADING, false);
    }
  },
};

const mutations = {
  [ADD_ITEM_TO_BASKET](state, obj) {
    if (!state.basket.length) {
      obj.selectCount = 1
      state.basket.push(obj);
      save();
      return;
    }
    const list = state.basket.filter(e => e.id === obj.id)
    if (list.length) {
      const item = state.basket.find(e => e.id === obj.id && e.selectSize === obj.selectSize);
      if(item){
        const index = state.basket.findIndex(e => e.id === obj.id && e.selectSize === obj.selectSize);
        obj.selectCount = state.basket[index].selectCount + 1
        Vue.set(state.basket, index , obj)
      }else{
        obj.selectCount = 1
        state.basket.push(obj);
      }
    } else {
      obj.selectCount = 1
      state.basket.push(obj);
    }
    save();
  },
  [ADD_ITEM_TO_FAVORITES](state, obj) {
    if (!state.favorites.length) {
      obj.selectCount = 1
      state.favorites.push(obj);
      saver();
      return;
    }
    const list = state.favorites.filter(e => e.id === obj.id)
    if (list.length) {
      const item = state.favorites.find(e => e.id === obj.id);
      if(item){
        const index = state.favorites.findIndex(e => e.id === obj.id);
        obj.selectCount = state.favorites[index].selectCount + 1
        Vue.set(state.favorites, index , obj)
      }else{
        obj.selectCount = 1
        state.favorites.push(obj);
      }
    } else {
      obj.selectCount = 1
      state.favorites.push(obj);
    }
    saver();
  },
  [REMOVE_ITEM_FROM_BASKET](state, obj) {
    const index = state.basket.findIndex(e => e.id === obj.id && e.selectSize === obj.selectSize);
    Vue.delete(state.basket, index)
    save();
  },
  [REMOVE_ITEM_FROM_FAVORITES](state, obj) {
    const index = state.favorites.findIndex(e => e.id === obj.id);
    Vue.delete(state.favorites, index)
    saver();
  },
  [RESET_BASKET](state) {
    localStorage.removeItem('products');
    state.basket = [];

  },
  [CHECK_PROMO_CODE_LOADING](state, status) {
    state.loadingPromoCode = status;
  },
  [INCREMENT_BASKET_LIST_COUNT](state, obj) {
    const index = state.basket.findIndex(e => e.id === obj.id && e.selectSize === obj.selectSize);
    obj.selectCount = obj.selectCount + 1;
    Vue.set(state.basket, index, obj);
    save();
  },
  [SET_BASKET_LIST_COUNT](state, obj) {
    const index = state.basket.findIndex(e => e.id === obj.id && e.selectSize === obj.selectSize);
    Vue.set(state.basket, index, obj);
    save();
  },
  [DECREMENT_BASKET_LIST_COUNT](state, obj) {
    const index = state.basket.findIndex(e => e.id === obj.id && e.selectSize === obj.selectSize);
    obj.selectCount = obj.selectCount - 1;
    Vue.set(state.basket, index, obj);
    save();
  },
  [SET_BASKET_EDIT_COUNT](state, val) {
    state.editCount = val;
  },
  [SET_PROMO_CODE_IN_BASKET](state, code) {
    state.promoCode = code;
  },
  [SET_DISCOUNT](state, discount) {
    state.discount = discount;
  },
  [RESET_DISCOUNT](state) {
    state.discount = null;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
