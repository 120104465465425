import Vue from 'vue';
import VueI18nManager from 'vue-i18n-manager';
import store from '@/store';
import router from '@/router';
import locales from '@/locales'


Vue.use(VueI18nManager, {
  store,
  router,
  config: {
    defaultCode: 'en-EN',
    languages: [
      {
        name: 'English',
        title: 'En.',
        key: 'en',
        code: 'en-EN',
        urlPrefix: 'en',
        translationKey: 'en'
      },
      {
        name: 'Italian',
        title: 'It.',
        key: 'it',
        code: 'it-IT',
        urlPrefix: 'it',
        translationKey: 'it',
      },
      {
        name: 'Spanish',
        title: 'Es.',
        key: 'es',
        code: 'es-ES',
        urlPrefix: 'es',
        translationKey: 'es',
      },
    ],
    translations: locales
  },
});
