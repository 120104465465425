import {mapActions, mapGetters, mapMutations} from "vuex";
import mainButton from '@/components/main-button/index.vue';
//sections

export default {
  name: "header-cart",
  components:{
    mainButton,
  },
  data(){
    return{
      selectSize: {},
    }
  },
  created() {
  },
  computed:{
    ...mapGetters({
      products: 'basket/basket'
    }),
    totalSum(){
      return this.products.reduce((accamulator, currentValue)=>{
        return accamulator + (currentValue.price * currentValue.selectCount)
      }, 0)
    }
  },

  methods:{
    ...mapMutations({
      removeItem: 'basket/REMOVE_ITEM_FROM_BASKET'
    }),
  }
}