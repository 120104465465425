import {$http} from '@/utils/https';

import {
    SET_CONTACTS_COUNTRY,
    GET_BRIDE_DATA,
    GET_SALON_DATA,
    BRIDE_LOADING,
    SALON_LOADING, SET_SOCIALS
} from '../mutation-types';
import {
    SET_BRIDE_DATA,
    SET_SALON_DATA,
    GET_CONTACT_COUNTRY, GET_SOCIALS
} from '../action-types';

const state = {
    countries: '',
    brideData: '',
    salonData: '',
    brideLoading: false,
    salonLoading: false,
    socials: ''
};

const getters = {
    countries: state => state.countries,
    brideData: state => state.brideData,
    salonData: state => state.salonData,
    brideLoading: state => state.brideLoading,
    salonLoading: state => state.salonLoading,
    socials: state => state.socials
};

const actions = {
    [GET_SOCIALS]: async ({commit}, payload) => {
        // commit(CHANGE_LOADING_SING_IN, true);
        try {
            const result = await $http.get('v1/contacts', payload);
            commit(SET_SOCIALS, result.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(CHANGE_LOADING_SING_IN, false)
        }
    },
    [GET_CONTACT_COUNTRY]: async ({commit}, payload) => {
        // commit(CHANGE_LOADING_SING_IN, true);
        try {
            const result = await $http.get('v1/feedback', payload);
            commit(SET_CONTACTS_COUNTRY, result.data.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(CHANGE_LOADING_SING_IN, false)
        }
    },
    [SET_BRIDE_DATA]: async ({commit}, payload) => {
        commit(BRIDE_LOADING, true);
        try {
            const response = await $http.post(`v1/feedback/bride?name&email&city&country&message`, payload);
            commit(GET_BRIDE_DATA, response.message);
        } catch (e) {
            throw e;
        } finally {
            commit(BRIDE_LOADING, false);
        }
    },
    [SET_SALON_DATA]: async ({commit}, payload) => {
        commit(SALON_LOADING, true);
        try {
            const response = await $http.post(`v1/feedback/salon?name&email&city&country&message`, payload);
            commit(GET_SALON_DATA, response.data.message);
        } catch (e) {
            throw e;
        } finally {
            commit(SALON_LOADING, false);
        }
    },

};

const mutations = {
    [SET_CONTACTS_COUNTRY](state, data) {
        state.countries = data;
    },
    [GET_BRIDE_DATA](state, data) {
        state.brideData = data;
    },
    [GET_SALON_DATA](state, data) {
        state.salonData = data;
    },
    [BRIDE_LOADING](state, status) {
        state.brideLoading = status
    },
    [SALON_LOADING](state, status) {
        state.salonLoading = status
    },
    [SET_SOCIALS](state, data) {
        state.socials = data
    }
};
export default {
    state,
    getters,
    actions,
    mutations,
};
