import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import VueAxios from 'vue-axios';
const camelcaseObjectDeep = require('camelcase-object-deep');

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

Vue.use(VueAxios, axios);

export const $http = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
    'Accept': 'application/json',
  }
});
$http.interceptors.request.use(async (config) => {
  let accessToken = await localStorage.getItem('user_token')
  config.headers['Authorization'] = `Bearer ${accessToken}`;
  config.headers['X-localization'] = store.getters.currentLanguage.translationKey;
  return config;
});
$http.interceptors.response.use((response) => {
    response.data = camelcaseObjectDeep(response.data);
    return response;
  }, (error) => {
  const originalRequest = error.config;
    switch (error.response.status) {
      case 500: {
        router.push({name: 'server-not-found'}).catch(() => {console.log()});
        break;
      }
      case 404: {
        router.push({name: 'page-not-found'}).catch(() => {console.log()});
        break;
      }
      // case 401: {
      //   router.push({name: 'sign-in'}).catch(() => {console.log()});
      //   store.commit('auth/REMOVE_TOKEN');
      //   break;
      // }
      default: {
        break;
      }
    }
  if (error.response.status === 401 && !originalRequest.retry) {

    let isRememberToken = localStorage.getItem('remember_token')
    if (!isRememberToken) {
      router.push({name: 'home'}).catch(() => {console.log()});
      store.commit(`auth/REMOVE_TOKEN`);
      return Promise.reject(camelcaseObjectDeep(error.response));
    }

    if (isRefreshing) {
      return new Promise(((resolve, reject) => {
        failedQueue.push({resolve, reject});
      })).then((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return Vue.axios(originalRequest);
      }).catch(error => error);
    }

    originalRequest.retry = true;
    isRefreshing = true;

    return new Promise(((resolve, reject) => {
      $http.post(`v1/auth/refresh-token?remember_token=${localStorage.getItem('remember_token')}`)
        .then(response => {
          store.commit(`auth/SET_TOKEN`, response.data);
          originalRequest.headers.Authorization =`Bearer ${response.data.accessToken}`;
          processQueue(null, response.data.accessToken);
          resolve(Vue.axios(originalRequest));
        })
        .catch((error) => {
          console.log(error);
          router.push({name: 'home'}).catch(() => {console.log()});
          store.commit(`auth/REMOVE_TOKEN`);
          processQueue(error, null);
          reject(error);
        })
        .then(() => {
          isRefreshing = false;
        });
    }));
  }
  return Promise.reject(camelcaseObjectDeep(error.response));
  }
);

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return $http
    }
  })
}
