<template>
  <div id="app" class="app">
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header/>
          <transition name="component-fade" mode="out-in">
            <router-view/>
          </transition>
        </div>
        <div class="wrapper-bottom">
          <main-footer/>
        </div>
      </div>
    </div>
    <hidden/>
    <CookieBar/>
    <transition name="component-fade" mode="out-in">
      <div class="global-loader" v-show="loaded && false">
        <loader :load="load"/>
      </div>
    </transition>
  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Hidden from '@/components/hidden/index.vue';
import MainHeader from '@/components/header/index.vue';
import MainFooter from '@/components/footer/index.vue';
import CookieBar from "./components/cookie-bar/index.vue";
import {isMobile, isIPad} from '@/helpers/variables/index'
import store from "./store";

export default {
  name: 'app',
  components: {
    Hidden,
    MainHeader,
    MainFooter,
    CookieBar
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      favorites: `basket/favorites`,
      variables: `setting/variables`
    })
  },
  data(){
    return{
      load: false,
      loaded: true,
    }
  },
  watch: {
    '$route'(nevWal){
      if(nevWal){
        // if(this.$route.name !== 'wedding' && this.$route.name !== 'evening' && this.$route.name !== 'accessories'){
        //   this.load = false
        //   this.loaded = true
        //   this.toggleLoad()
        // }
      }
    }
  },
  created() {
    this.toggleLoad()
    this.fetchContacts()
  },
  mounted() {
    // if (localStorage.getItem('user_token')) {
    //   store.commit(`auth/SET_TOKEN`, localStorage.getItem('user_token'));
    // }

    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      fetchContacts: 'pages/GET_CONTACT',
    }),
    toggleLoad(){
      setTimeout(()=>{
        this.load = true
        setTimeout(()=>{
          this.loaded = false
        }, 1000)
      }, 3000)
      console.log(this.loaded);
    }
  }
}
</script>
