import {mapGetters, mapMutations} from "vuex";

export default {
  name: "footer",
  computed:{
    ...mapGetters({
      contacts: 'pages/contact',
      categoriesList: 'category/categoriesList',
      productList: 'catalog/list',
      productsLoading: 'catalog/productLoading',
    }),
    showFooter(){
      if(this.$route.name !== 'evening' && this.$route.name !== 'wedding' && this.$route.name !== 'accessories'){
        return true
      } else {
        if(this.productList && !this.productsLoading){
          return true
        } else {
          return false
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      // changeLoginPopup:'popups/CHANGE_LOGIN_POPUP'
    })
  }
}