export default {
  nextCollection: 'prossima collezione',
  successAuth: 'Successo',
  successOrder: 'Ordine inviato con successo',
  successUpdateInfo: 'Aggiornato con successo',
  addCart: 'Aggiunto al carrello con successo',
  errorRequired: 'Si prega di compilare questo campo',
  errorMaxLength: 'Troppi caratteri',
  errorMinLength: 'Non abbastanza caratteri',
  errorEmail: 'Inserisci l\'email corretta',
  validationSameAs: 'Mancata corrispondenza della password',
  form: {
    fname: 'Nome di battesimo',
    lname: 'Cognome',
    email: 'E-mail',
    phone: 'Telefono',
    country: 'Scegli il tuo paese',
    cpass: 'Immetti la password corrente*',
    npass: 'Inserire una nuova password*',
    rpass: 'Conferma la nuova password*',
    zip: 'Cap',
    comment: 'Commento',
    adress: 'Un abito',
    city: 'Città',
    pass: 'Parola d\'ordine',
    confirmPass: 'Conferma password',
  },
  home: {
    designer: {
      title: 'progettista',
    },
    about: {
      desc1: 'Katy è  tenerezza e autosufficienza.',
      desc2: 'Eleganza e facilità nell’incarnazione naturale dell\'essere Femminea. Questo è esattamente il modo in cui passeggiava per le strade del romantico Cadaqués.',
      desc3: 'La Catalogna romantica come non mai rivelava la vera natura di Katy. Questo è esattamente il modo in cui ascoltava la straordinaria musica dei venti sulla costa di Playa de las Catedrales.',
      desc4: 'Questo è esattamente il modo in cui sognava di incarnare la sua natura e la sua raffinatezza nei disegni, che sono diventati capolavori unici nelle manifestazioni del sacro per ogni Donna - Abiti.',
      desc5: `Ognuna delle sue creazioni è legata con forti fili con la Femminilità di Katy. Il suo fascino si fa sentire in ogni dettaglio.`,
      desc6: 'Sentilo e tu.',
      desc7: 'Diventa un Sogno - vestendo Katy.',
      desc8: 'Katy Corso.',
    }
  },
  contact:{
    link:'Contattaci',
    title:'Contattaci',
    formName:'Nome*',
    formEmail:'E-mail*',
    formCity:'Città*',
    formCountry:'Nazione*',
    formMessage:'Il tuo messaggio*',
    formTitle:'informazioni sulla persona di contatto',
    salonInformation:'INFORMAZIONI sul salone',
    salonContactInformation:'INFORMAZIONI SULLA PERSONA DI CONTATTO',
    salonTitle:'Titolo del salone',
    salonStreet:'Strada',
    salonCity:'Città',
    salonCountry:'Nazione',
    salonPostCode:'Codice postale',
    salonPhone:'Telefono*',
    salonWebPage:'Pagina web',
    salonSquareMeters:'Metri quadrati',
    salonBrands:'Marchi rappresentati*',
    salonMessage:'Marchi rappresentati*',
    salonSendMessage:'Invia messaggio',
    salonSuccessMessage: 'messaggio inviato con successo',
    salonErrorMessage: 'Error al enviar el mensaje'
  },
  header: {
    video: 'video',
    blog: 'blog',
    meeting: 'riunione',
    retailers: 'rivenditori',
    about: 'di',
    size: 'tabella delle taglie',
    favorite: 'Visualizza preferito',
    cart: 'Guardare',
    total: 'Totale',
    katyKiss:'Katy kiss',
  },
  blog: {
    title: 'blog',
  },
  meeting: {
    title: 'luogo d\'incontro',
  },
  retailers: {
    title: 'TROVA UN NEGOZIO',
    subTitle: 'Il tuo paese non è nella lista? Contatta il nostro ',
    subTitleLink: 'responsabile',
    selectCountry: 'Scegli il tuo paese',
    selectCity: 'Scegli la tua città',
    map: 'Mostra sulla mappa',
  },
  about: {
    title: 'CHI SIAMO',
  },
  size: {
    title: 'Tabella delle taglie',
    breast: 'Seno',
    waist: 'vita',
    hips: 'fianchi',
    subTitle: 'Avete domande? Il tuo',
    subTitleLink: 'manager può aiutarti',
  },
  profile: {
    title: 'IL MIO ACCOUNT',
    info: 'INFORMAZIONE PERSONALE',
    orders: 'ORDINI',
    favorite: 'PREFERITE',
    logout: 'Disconnessione',
    personal: {
      title1: 'Informazione personale',
      title2: 'Cambia la password',
      button: 'Salvare le modifiche',
    },
    order: {
      title: 'Ordini',
      number: 'Numero d\'ordine',
      date: 'Data',
      status: 'Stato',
      amount: 'Quantità',
      total: 'Totale',
      size: 'Taglia',
      quantity: 'Quantità',
    },
  },
  order: {
    title: 'ORDINE',
    order: 'ORDINE',
    price: 'PREZZO',
    quantity: 'QUANTITÀ',
    weight: 'IL PESO',
    amount: 'QUANTITÀ',
    size: 'Taglia',
    info: 'INFORMAZIONE PERSONALE',
    delivery: 'METODO DI CONSEGNA',
    summary: 'RIEPILOGO',
    subtotal: 'Totale parziale',
    shipping: 'Spedizione',
    total: 'Totale',
    confirm: 'Conferma l\'ordine',
    thank: 'GRAZIE PER AVER ORDINATO!',
    shop: 'Torna allo shopping',
    back: 'Torna alla pagina principale',
  },
  auth: {
    title: 'IL MIO ACCOUNT',
    signin: 'Accesso',
    register: 'Registrati',
    sign: {
      or: 'o',
      forgot: 'Hai dimenticato la password?',
      remember: 'Ricordati di me',
      button: 'Accesso',
    },
    registration: {
      title: 'Una volta effettuato l\'accesso, non riceverai spam sulla tua email',
      agree: 'Accetto i Termini e condizioni e l\'Informativa sulla privacy',
      button: 'Registrati',
      success: 'Ti abbiamo inviato un\'e-mail con le informazioni necessarie per confermare il tuo account',
    },
    reset: {
      title: 'RESETTA LA PASSWORD',
      button: 'Spedire una lettera',
      back: 'Torna al login',
      success: 'Ti abbiamo inviato un\'e-mail con le informazioni necessarie per reimpostare la password',
    },
    restore: {
      title: 'cambia la password',
      button: 'Cambia la password',
      success: 'Congratulazioni! la password è stata cambiata con successo',
      acc: 'Al mio account',
      back: 'Torna alla pagina principale',
    },
  },
  product: {
    size: 'Taglia',
    sizeGuide: 'Guida alle taglie',
    choose: 'Scegli la tua taglia',
    addToCart: 'Aggiungi al carrello',
    retailers: 'Rivenditori',
    collection: 'collezione',
    store: 'Trova un negozio',
  },
}
