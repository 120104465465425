import {mapGetters, mapMutations} from "vuex";

export default {
  name: "cookie",
  data(){
    return{
      showBar: false,
    }
  },
  watch: {
    $route: {
      handler(newVal){
        if(newVal){
          this.checkCookies()
        }
      }
    }
  },
  computed:{
    ...mapGetters({
    }),
  },
  methods: {
    ...mapMutations({
    }),
    setCookieAccept(){
      sessionStorage.setItem('acceptedCookies', 'true')
      this.consentGranted()
      this.showBar = !this.showBar
    },
    checkCookies(){
      if(!sessionStorage.getItem('acceptedCookies')){
        setTimeout(()=>{
          this.showBar = !this.showBar
        }, 3000)
      }
    },
    consentGranted(){
      gtag('consent', 'update', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
      });
    }
  }
}