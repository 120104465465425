import {mapGetters, mapMutations} from "vuex";
import mainButton from '@/components/main-button/index.vue';
//sections

export default {
  name: "header-favorites",
  components:{
    mainButton,
  },
  data(){
    return{
      selectSize: {},
    }
  },
  created() {

  },
  computed:{
    ...mapGetters({
      favoriteList: 'basket/favorites',
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },

  methods:{
    ...mapMutations({
    })
  }
}