export default {
  nextCollection: 'próxima colección',
  successAuth: 'Éxito',
  successOrder: 'Pedido enviado con éxito',
  successUpdateInfo: 'Actualizado exitosamente',
  addCart: 'Agregado al carrito con éxito',
  errorRequired: 'Por favor complete este campo',
  errorMaxLength: 'Demasiados personajes',
  errorMinLength: 'Muy pocos personajes',
  errorEmail: 'Ingrese el correo electrónico correcto',
  validationSameAs: 'Contraseña no coincide',
  form: {
    fname: 'Nombre de pila',
    lname: 'Apellido',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    country: 'Elige tu país',
    cpass: 'Introducir la contraseña actual*',
    npass: 'Ingrese nueva clave*',
    rpass: 'Confirmar nueva contraseña*',
    zip: 'Código postal',
    comment: 'Comentario',
    adress: 'Dirección',
    city: 'Ciudad',
    pass: 'Contraseña',
    confirmPass: 'Confirmar Contraseña',
  },
  home: {
    designer: {
      title: 'Diseñadora',
    },
    about: {
      desc1: 'Katy es la ternura y autosuficiencia.',
      desc2: 'Es la refinación y facilidad combinadas en la realización natural de ser Feminil.',
      desc3: 'Ella era precisamente así cuando paseaba por las calles de Cadaqués romántico. Delicada, refina, agraciada: espectacular como el alba. ',
      desc4: 'Cataluña romántica como nunca abría la natura verdadera de Katy. Era precisamente así cuando escuchaba la música fantástica de vientos en la costa de la Playa de las Catedrales, admiraba la luz fabulosamente brillante por encima de las cumbres, se embriagaba por los sonidos de la guitarra española.',
      desc5: `Era precisamente así cuando soñaba con encarnar su natura y refinación en los dibujos que llegaron a ser las obras maestras únicas representadas en lo sagrado para cada Mujer – el Vestido.`,
      desc6: 'Siéntelo tú.',
      desc7: 'Conviértete en el Sueño al ponerte Katy. ',
      desc8: 'Katy Corso.',
    }
  },
  contact:{
    link:'contacto',
    title:'Contacta con nosotras',
    formName:'Nombre*',
    formEmail:'E-mail*',
    formCity:'Ciudad*',
    formCountry:'País*',
    formMessage:'Su mensaje*',
    formTitle:'información de la persona de contacto',
    salonInformation:'INFORMACIÓN del salón',
    salonContactInformation:'INFORMACIÓN DE LA PERSONA DE CONTACTO',
    salonTitle:'Título del salón',
    salonStreet:'Calle',
    salonCity:'Ciudad',
    salonCountry:'País',
    salonPostCode:'Código postal',
    salonPhone:'Teléfono*',
    salonWebPage:'Página web',
    salonSquareMeters:'Metros cuadrados',
    salonBrands:'Marcas representadas*',
    salonMessage:'Marcas representadas*',
    salonSendMessage:'Enviar mensaje',
    salonSuccessMessage: 'Mensaje enviado con éxito',
    salonErrorMessage: 'Mensaje enviado con éxito'
  },
  header: {
    video: 'video',
    blog: 'blog',
    meeting: 'cita',
    retailers: 'minoristas',
    about: 'sobre',
    size: 'tabla de tallas',
    favorite: 'Ver favorito',
    cart: 'Verificar',
    total: 'Total',
    katyKiss:'Katy kiss',
  },
  blog: {
    title: 'blog',
  },
  meeting: {
    title: 'punto de reunión',
  },
  retailers: {
    title: 'ENCUENTRA UNA TIENDA',
    subTitle: '¿Tu país no está en la lista? Póngase en contacto con nuestro ',
    subTitleLink: 'gerente',
    selectCountry: 'Elige tu país',
    selectCity: 'Elige tu ciudad',
    map: 'Mostrar en el mapa',
  },
  about: {
    title: 'Sobre nosotros',
  },
  size: {
    title: 'Tabla de tallas',
    breast: 'seno',
    waist: 'cintura',
    hips: 'caderas',
    subTitle: '¿Tiene usted alguna pregunta?',
    subTitleLink: 'Tu jefe puede ayudarte',
  },
  profile: {
    title: 'MI CUENTA',
    info: 'INFORMACION PERSONAL',
    orders: 'PEDIDOS',
    favorite: 'FAVORITAS',
    logout: 'Desconectar',
    personal: {
      title1: 'Informacion personal',
      title2: 'Cambiar la contraseña',
      button: 'Guardar cambios',
    },
    order: {
      title: 'Pedidos',
      number: 'Número de orden',
      date: 'Fecha',
      status: 'Estado',
      amount: 'Monto',
      total: 'Total',
      size: 'Tamaño',
      quantity: 'Cantidad',
    },
  },
  order: {
    title: 'PEDIDO',
    order: 'PEDIDO',
    price: 'PRECIO',
    quantity: 'CANTIDAD',
    weight: 'PESO',
    amount: 'MONTO',
    size: 'Tamaño',
    info: 'INFORMACION PERSONAL',
    delivery: 'MÉTODO DE ENTREGA',
    summary: 'RESUMEN',
    subtotal: 'Total parcial',
    shipping: 'Transporte',
    total: 'Total',
    confirm: 'Confirmar el pedido',
    thank: '¡GRACIAS POR HACER UN PEDIDO!',
    shop: 'De vuelta a las compras',
    back: 'Volver a la pagina principal',
  },
  auth: {
    title: 'MI CUENTA',
    signin: 'Iniciar sesión',
    register: 'Registrarse',
    sign: {
      or: 'o',
      forgot: '¿Olvidaste tu contraseña?',
      remember: 'Recuérdame',
      button: 'Iniciar sesión',
    },
    registration: {
      title: 'Una vez que inicie sesión, no recibirá spam en su correo electrónico.',
      agree: 'Acepto los Términos y condiciones y la Política de privacidad',
      button: 'Registrarse',
      success: 'Le hemos enviado un correo electrónico con la información necesaria para confirmar su cuenta.',
    },
    reset: {
      title: 'RESTABLECER LA CONTRASEÑA',
      button: 'Enviar una carta',
      back: 'Atrás para iniciar sesión',
      success: 'Le hemos enviado un correo electrónico con la información necesaria para restablecer su contraseña.',
    },
    restore: {
      title: 'cambiar la contraseña',
      button: 'Cambiar la contraseña',
      success: '¡Felicidades! la contraseña se cambió con éxito',
      acc: 'A mi cuenta',
      back: 'Volver a la pagina principal',
    },
  },
  product: {
    size: 'Tamaño',
    sizeGuide: 'Guia de tallas',
    choose: 'Elige tu talla',
    addToCart: 'Añadir a la cesta',
    retailers: 'Minoristas',
    collection: 'colección',
    store: 'Encuentra una tienda',
  },
}
