import {$http} from '@/utils/https'
import {CHANGE_PRODUCTS_LOADING, SET_DRESS, SET_PRODUCTS, SET_COLLECTIONS, CHANGE_COLLECTIONS_LOADING, SET_TOP_POSITION} from '../mutation-types';

import {GET_PRODUCTS, GET_DRESS, GET_COLLECTIONS} from '../action-types';

const state = {
  list: null,
  dress: null,
  productLoading: false,
  top: null,
  collections: null,
  collectionsLoading: false,
}
const getters = {
  list: state => state.list,
  dress: state => state.dress,
  collections: state => state.collections,
  collectionsLoading: state => state.collectionsLoading,
  productLoading: state => state.productLoading,
  top: state => state.top,
}
const actions = {
  [GET_COLLECTIONS]: async ({commit}, payload) => {
    commit(CHANGE_COLLECTIONS_LOADING, true);
    try {
      const response = await $http.get(`v1/category/show?slug=${payload}`);
      commit(SET_COLLECTIONS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_COLLECTIONS_LOADING, false);
    }
  },
  [GET_PRODUCTS]: async ({commit}, payload) => {
    commit(CHANGE_PRODUCTS_LOADING, true);
    try {
      const response = await $http.get(`v1/collection/show?slug=${payload}`);
      commit(SET_PRODUCTS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PRODUCTS_LOADING, false);
    }
  },
  [GET_DRESS]: async ({commit}, payload) => {
    commit(CHANGE_PRODUCTS_LOADING, true);
    try {
      const response = await $http.get(`v1/dress/show?slug=${payload}`);
      commit(SET_DRESS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PRODUCTS_LOADING, false);
    }
  },
}
const mutations = {
  [SET_PRODUCTS](state, obj) {
    state.list = null;
    obj.dresses.data.map(el => {
        el.selected = false
    })
    state.list = obj;
  },
  [SET_DRESS](state, obj) {
    obj.selected = false;
    state.dress = null;
    state.dress = obj;
  },
  [SET_TOP_POSITION](state, data) {
    state.top = data;
  },
  [SET_COLLECTIONS](state, list) {
    state.collections = list;
  },
  [CHANGE_COLLECTIONS_LOADING](state, status) {
    state.collectionsLoading = status;
  },
  [CHANGE_PRODUCTS_LOADING](state, status) {
    state.productLoading = status;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}