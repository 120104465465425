import axios from 'axios'
import {$http} from '@/utils/https'
import {
  CHANGE_ADDRESS,
  CHANGE_DIALOG_QUICK_ORDER,
  NOVA_POSHTA_GET_DEPARTMENS_LOADING,
  NOVA_POSHTA_GET_TOWNS_LOADING,
  NOVA_POSHTA_SET_DEPARTMENS,
  NOVA_POSHTA_SET_TOWNS,
  RESET_ADDRESS,
  CHANGE_DELIVERY_TYPE,
  CHANGE_USER_INFO,
  RESET_CHANGE_USER_INFO,
  CHANGE_LOADING,
  CHANGE_SUCCESS_BUY,
  SET_INVOICE,
  CHANGE_INVOICE_LOADING,
  SET_TRANSACTION,
  CHANGE_TRANSACTION_LOADING,
  RESET_PAYMENT,
  SET_ORDER, CHANGE_CONTENT_LOADING, SET_CONTENT, SET_DELIVERY, CHANGE_DELIVERY_LOADING, CHANGE_COUNTRY_LOADING, SET_COUNTRY

} from '../mutation-types';

import {
  NOVA_POSHTA_GET_DEPARTMENS, NOVA_POSHTA_GET_TOWNS, CREATE_ORDER, CREATE_INVOICE,
  CREATE_TRANSACTION, GET_DELIVERY, GET_COUNTRY
} from '../action-types';


const state = {
  invoiceResult: null,
  isInvoiceLoading: false,
  transactionResult: null,
  isTransactionLoading: false,
  orderResult: null,
  productId: null,
  delivery: null,
  deliveryLoading: false,
  country: null,
  countryLoading: false,

  showDialogQuickOrder: false,
  successBuy: false,
  isLoading: false,
  userInfo: {},
  address: {
    town: 'Киев',
  },
  isDepartmensLoading: false,
  isTownsLoading: false,
  towns: [],
  departments: [],

  deliveryNovaPoshtaData: {},
  deliveryCourierData: {},
  deliveryType: 'couirer'
};

const getters = {
  invoiceResult: state => state.invoiceResult,
  isInvoiceLoading: state => state.isInvoiceLoading,
  transactionResult: state => state.transactionResult,
  isTransactionLoading: state => state.isTransactionLoading,
  productId: state => state.productId,
  orderResult: state => state.orderResult,
  delivery: state => state.delivery,
  deliveryLoading: state => state.deliveryLoading,
  country: state => state.country,
  countryLoading: state => state.countryLoading,

  showDialogQuickOrder: state => state.showDialogQuickOrder,
  isLoading: state => state.isLoading,
  address: state => state.address,
  userInfo: state => state.userInfo,
  isDepartmensLoading: state => state.isDepartmensLoading,
  isTownsLoading: state => state.isTownsLoading,
  towns: state => state.towns,
  departments: state => state.departments,

  deliveryNovaPoshtaData: state => state.deliveryNovaPoshtaData,
  deliveryCourierData: state => state.deliveryCourierData,
  deliveryType: state => state.deliveryType,
  successBuy: state => state.successBuy,
};

const actions = {
  [CREATE_INVOICE]: async ({commit}, payload) => {
    try {
      commit(CHANGE_INVOICE_LOADING, true);
      const response = await $http.post(`v1/payment/handle-payment?order_id=${payload.id}&method=${payload.method}`);
      commit(SET_INVOICE, response.data);
      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_INVOICE_LOADING, false);
    }
  },
  [CREATE_TRANSACTION]: async ({commit}, payload) => {
    try {
      commit(CHANGE_TRANSACTION_LOADING, true);

      const response = await $http.post('v1/transactions', payload);
      if(response.data?.data?.value){
        commit(SET_INVOICE, response.data.data);
      }
      commit(SET_TRANSACTION, response.data);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_TRANSACTION_LOADING, false);
    }
  },

  [CREATE_ORDER]: async ({commit}, payload) => {
    commit(CHANGE_LOADING, true);
    try {
      const response = await $http.post(`v1/order`, payload);
      commit(SET_ORDER, response.data.message);
      commit('auth/SET_TOKEN', response.data, { root: true });
      return response.data.data
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING, false);
    }
  },
  [NOVA_POSHTA_GET_TOWNS]: async ({commit}, payload) => {
    commit(NOVA_POSHTA_GET_TOWNS_LOADING, true);
    try {
      const response = await axios({
        method: 'POST',
        url: "https://api.novaposhta.ua/v2.0/json/",
        data: payload
      })
      commit(NOVA_POSHTA_SET_TOWNS, response.data.data)
      commit(NOVA_POSHTA_SET_DEPARTMENS, [])
    } catch (e) {
      throw e;
    } finally {
      commit(NOVA_POSHTA_GET_TOWNS_LOADING, false);
    }
  },
  [NOVA_POSHTA_GET_DEPARTMENS]: async ({commit}, payload) => {
    commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, true);
    try {
      const response = await axios({
        method: 'POST',
        url: "https://api.novaposhta.ua/v2.0/json/",
        data: payload
      })
      commit(NOVA_POSHTA_SET_DEPARTMENS, response.data.data)
    } catch (e) {
      throw e;
    } finally {
      commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, false);
    }
  },
  [GET_DELIVERY]: async ({commit}) => {
    commit(CHANGE_DELIVERY_LOADING, true);
    try {
      const response = await $http.get(`v1/delivery_methods`);
      commit(SET_DELIVERY, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_DELIVERY_LOADING, false);
    }
  },
  [GET_COUNTRY]: async ({commit}) => {
    commit(CHANGE_COUNTRY_LOADING, true);
    try {
      const response = await $http.get(`v1/order/countries`);
      commit(SET_COUNTRY, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_COUNTRY_LOADING, false);
    }
  },
};

const mutations = {
  [SET_ORDER](state, data) {
    state.orderResult = data;
  },
  [SET_DELIVERY](state, data) {
    state.delivery = data;
  },
  [CHANGE_DELIVERY_LOADING](state, status) {
    state.deliveryLoading = status;
  },
  [SET_COUNTRY](state, data) {
    state.country = data;
  },
  [CHANGE_COUNTRY_LOADING](state, status) {
    state.countryLoading = status;
  },
  [SET_TRANSACTION](state, data) {
    state.transactionResult = data;
  },
  [CHANGE_TRANSACTION_LOADING](state, status) {
    state.isTransactionLoading = status;
  },
  [RESET_PAYMENT](state, id) {
    state.productId = null;
    state.orderResult = null;
    state.invoiceResult = null;
    state.transactionResult = null;
  },
  [SET_INVOICE](state, data) {
    state.invoiceResult = data;
  },
  [CHANGE_INVOICE_LOADING](state, status) {
    state.isInvoiceLoading = status;
  },
  [CHANGE_DIALOG_QUICK_ORDER](state, status) {
    state.showDialogQuickOrder = status;
  },
  [CHANGE_LOADING](state, status) {
    state.isLoading = status;
  },
  [CHANGE_ADDRESS](state, data) {
    Object.assign(state.address, data)
  },
  [CHANGE_USER_INFO](state, data) {
    Object.assign(state.userInfo, data)
  },
  [CHANGE_DELIVERY_TYPE](state, val) {
    state.deliveryType = val;
  },
  [CHANGE_SUCCESS_BUY](state, val) {
    state.successBuy = val;
  },
  [RESET_ADDRESS](state) {
    for (let i in state.address) {
      state.address[i] = ''
    }
  },
  [RESET_CHANGE_USER_INFO](state) {
    for (let i in state.userInfo) {
      state.userInfo[i] = ''
    }
  },
  [NOVA_POSHTA_GET_DEPARTMENS_LOADING](state, status) {
    state.isDepartmensLoading = status
  },
  [NOVA_POSHTA_GET_TOWNS_LOADING](state, status) {
    state.isTownsLoading = status
  },
  [NOVA_POSHTA_SET_TOWNS](state, data) {
    state.towns = data
  },
  [NOVA_POSHTA_SET_DEPARTMENS](state, data) {
    state.departments = data
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
