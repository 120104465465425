import {$http} from '@/utils/https';

import {CHANGE_LOADING_LOCATION, SET_LOCATION, SET_COUNTRIES, CHANGE_LOADING_COUNTRIES, SET_CITIES, CHANGE_LOADING_CITIES} from '../mutation-types';

import {GET_LOCATION, GET_COUNTRIES, GET_CITIES, GET_SHOP} from '../action-types';

const state = {
  location: null,
  countries: null,
  cities: [
    {
      cities: [
        {
          city: null,
        }
      ]
    }
  ],
  locationLoading: false,
  countriesLoading: false,
  citiesLoading: false,
};

const getters = {
  location: state => state.location,
  countries: state => state.countries,
  cities: state => state.cities,
  locationLoading: state => state.locationLoading,
  citiesLoading: state => state.citiesLoading,
};

const actions = {
  [GET_LOCATION]: async ({commit}, location) => {
    commit(CHANGE_LOADING_LOCATION, true);
    try {
      const result = await $http.get(`v1/shop/find-by-geo?latitude=${location.lat}&longitude=${location.lon}`);
      commit(SET_LOCATION, result.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_LOCATION, false)
    }
  },
  [GET_COUNTRIES]: async ({commit}) => {
    commit(CHANGE_LOADING_COUNTRIES, true);
    try {
      const result = await $http.get(`v1/shop`);
      commit(SET_COUNTRIES, result.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_COUNTRIES, false)
    }
  },
  [GET_CITIES]: async ({commit}, country) => {
    commit(CHANGE_LOADING_CITIES, true);
    try {
      const result = await $http.get(`v1/shop/find-by-country?country=${country.country}`);
      commit(SET_CITIES, result.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_CITIES, false)
    }
  },
  [GET_SHOP]: async ({commit}, city) => {
    commit(CHANGE_LOADING_LOCATION, true);
    try {
      const result = await $http.get(`v1/shop/find-by-city?city=${city.city}`);
      commit(SET_LOCATION, result.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_LOCATION, false)
    }
  },
};

const mutations = {
  [SET_LOCATION](state, data) {
    state.location = data
  },
  [SET_COUNTRIES](state, data) {
    state.countries = data
  },
  [SET_CITIES](state, data) {
    state.cities = data
  },
  [CHANGE_LOADING_LOCATION](state, status) {
    state.locationLoading = status;
  },
  [CHANGE_LOADING_COUNTRIES](state, status) {
    state.countriesLoading = status;
  },
  [CHANGE_LOADING_CITIES](state, status) {
    state.citiesLoading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
