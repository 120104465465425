export default {
    nextCollection: 'next collection',
    successAuth: 'Success',
    successOrder: 'Order sent successfully',
    successUpdateInfo: 'Successfully updated',
    addCart: 'Added to cart successfully',
    errorRequired: 'Please fill this field',
    errorMaxLength: 'Too many characters',
    errorMinLength: 'Not enough characters',
    errorEmail: 'Enter correct Email',
    validationSameAs: 'Password mismatch',
    form: {
        fname: 'First name',
        lname: 'Last name',
        email: 'E-mail',
        phone: 'Phone',
        country: 'Choose Your Country',
        cpass: 'Enter current password*',
        npass: 'Enter new password*',
        rpass: 'Confirm new password*',
        zip: 'Zip code',
        comment: 'Comment',
        adress: 'Adress',
        city: 'City',
        pass: 'Password',
        confirmPass: 'Confirm password',
    },
    contact: {
        link: 'contact us',
        title: 'contact us',
        formName: 'Name*',
        formEmail: 'E-mail*',
        formCity: 'City*',
        formCountry: 'Country*',
        formMessage: 'Your message*',
        formTitle: 'contact person information',
        salonInformation: 'salon INFORMATION',
        salonContactInformation: 'CONTACT PERSON INFORMATION',
        salonTitle: 'Salon title',
        salonStreet: 'Street',
        salonCity: 'City',
        salonCountry: 'Country',
        salonPostCode: 'Post code',
        salonPhone: 'Phone*',
        salonWebPage: 'Webpage',
        salonSquareMeters: 'Square meters',
        salonBrands: 'Represented brands*',
        salonMessage: 'Your message*',
        salonSendMessage: 'Send message',
        salonSuccessMessage: 'Message sent successfully',
        salonErrorMessage: 'Error al enviar el mensaje'
    },
    home: {
        designer: {
            title: 'Designer',
        },
        about: {
          desc1: 'Katy is softness and self-sufficiency.',
          desc2: 'Elegance and lightness in natural appearance of womanhood. And just like that she walks on the streets of romantic Cadaques.',
          desc3: 'Romantic Catalunya like never before opens the true beauty of Katy. Just like that she listened to great music of winds on the seaside of Playa de Las Catedrale.',
          desc4: 'She dreamed to make real her natural personality and elegance in the patterns that became the unique masterpieces displaying the preciousness of every woman - the dress.',
          desc5: `Her each creation tied up with womanhood of Katy.<br>
            Her charm is in every detail.`,
          desc6: 'Feel it yourself.',
          desc7: 'Become the dream wearing Katy.',
          desc8: 'Katy Corso.',
        }
    },
    header: {
        video: 'video',
        blog: 'blog',
        meeting: 'meeting',
        retailers: 'retailers',
        about: 'about us',
        size: 'size chart',
        favorite: 'View favourite',
        cart: 'Checkout',
        total: 'Total',
        katyKiss:'Katy kiss',
    },
    blog: {
        title: 'blog',
    },
    meeting: {
        title: 'meeting place',
    },
    retailers: {
        title: 'FIND A STORE',
        subTitle: 'Is your country not on the list? Contact our ',
        subTitleLink: 'manager',
        selectCountry: 'Choose Your Country',
        selectCity: 'Choose Your City',
        map: 'Show on the map',
    },
    about: {
        title: 'ABOUT US',
    },
    size: {
        title: 'Size chart',
        breast: 'breast',
        waist: 'waist',
        hips: 'hips',
        subTitle: 'Do you hane any questions? Our',
        subTitleLink: 'manager can help you',
    },
    profile: {
        title: 'MY ACCOUNT',
        info: 'PERSONAL INFORMATION',
        orders: 'ORDERS',
        favorite: 'FAVOURITES',
        logout: 'Sign out',
        personal: {
            title1: 'Personal information',
            title2: 'Change password',
            button: 'Save changes',
        },
        order: {
            title: 'Orders',
            number: 'Order number',
            date: 'Date',
            status: 'Status',
            amount: 'Amount',
            total: 'Total',
            size: 'Size',
            quantity: 'Quantity',
        },
    },
    order: {
        title: 'ORDER',
        order: 'ORDER',
        price: 'PRICE',
        quantity: 'QUANTITY',
        weight: 'WEIGHT',
        amount: 'AMOUNT',
        size: 'Size',
        info: 'PERSONAL INFORMATION',
        delivery: 'DELIVERY METHOD',
        summary: 'SUMMARY',
        subtotal: 'Subtotal',
        shipping: 'Shipping',
        total: 'Total',
        confirm: 'Confirm the order',
        thank: 'THANK YOU FOR ORDERING!',
        shop: 'Back to shopping',
        back: 'Back to the main page',
    },
    auth: {
        title: 'MY ACCOUNT',
        signin: 'Sign in',
        register: 'Register',
        sign: {
            or: 'or',
            forgot: 'Forgot your password?',
            remember: 'Remember me',
            button: 'Sign in',
        },
        registration: {
            title: 'Once you sign in, you will not receive spam on your email',
            agree: 'I agree to the Terms and Conditions and Privacy Policy',
            button: 'Register',
            success: 'We have sent you an email with the information needed to confirm your account',
        },
        reset: {
            title: 'RESET PASSWORD',
            button: 'Send a letter',
            back: 'Back to login',
            success: 'We have sent you an email with the information needed to reset your password',
        },
        restore: {
            title: 'change password',
            button: 'Change password',
            success: 'congratulations! password was changed successfully',
            acc: 'To my account',
            back: 'Back to the main page',
        },
    },
    product: {
        size: 'Size',
        sizeGuide: 'Size guide',
        choose: 'Choose Your Size',
        addToCart: 'Add to cart',
        retailers: 'Retailers',
        collection: 'collection',
        store: 'Find a store',
    },
}
