import axios from 'axios'
import {$http} from '@/utils/https'

import {
  CHANGE_CONTENT_LOADING,
  SET_CONTENT,
  SET_PAGE_CONTENT,
  SET_PAGE,
  SET_BANNER_CONTENT,
  SET_BLOG,
  SET_MAGAZINES,
  SET_CONTACT,
  SET_TRUNK,
  SET_TRUNK_SHOW,
  CHANGE_PAGE_SKIP,
  RESET_SKIP
} from '../mutation-types';

import {GET_CONTENT, GET_MAGAZINES, GET_TRUNK, GET_TRUNK_SHOW, GET_PAGE_CONTENT, GET_PAGE, GET_BANNER_CONTENT, GET_BLOG, GET_CONTACT} from "../action-types";

const state = {
  content: {},
  bannerContent: {},
  pageContent: {},
  contact: null,
  magazines: null,
  trunk: null,
  trunkShow: null,
  page: {},
  blog: {},
  contentLoading: false,
  skip: 0,
  limit: 9,
};

const getters = {
  content: state => state.content,
  contact: state => state.contact,
  pageContent: state => state.pageContent,
  contentLoading: state => state.contentLoading,
  page: state => state.page,
  blog: state => state.blog,
  trunk: state => state.trunk,
  trunkShow: state => state.trunkShow,
  bannerContent: state => state.bannerContent,
  limit: state => state.limit,
  magazines: state => state.magazines,
};

const actions = {
  [GET_CONTENT]: async ({commit}, payload) => {
    commit(CHANGE_CONTENT_LOADING, true);
    try {

      const response = await $http.get(`v1/content?slug=${payload}&skip=${state.skip}&limit=${state.limit}`);
      commit(SET_CONTENT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_CONTENT_LOADING, false);
    }
  },
  [GET_CONTACT]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/contacts`);
      commit(SET_CONTACT, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_TRUNK]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/trunk-show`);
      commit(SET_TRUNK, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_TRUNK_SHOW]: async ({commit}, slug) => {
    try {
      const response = await $http.get(`v1/trunk-show/${slug}`);
      commit(SET_TRUNK_SHOW, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_MAGAZINES]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/magazines`);
      commit(SET_MAGAZINES, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_BANNER_CONTENT]: async ({commit}, payload) => {
    commit(CHANGE_CONTENT_LOADING, true);
    try {
      const response = await $http.get(`v1/content?slug=${payload}`);
      commit(SET_BANNER_CONTENT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_CONTENT_LOADING, false);
    }
  },
  [GET_PAGE_CONTENT]: async ({commit}, payload) => {
    commit(CHANGE_CONTENT_LOADING, true);
    try {
      const response = await $http.get(`v1/content/show?slug=${payload}`);
      commit(SET_PAGE_CONTENT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_CONTENT_LOADING, false);
    }
  },
  [GET_PAGE]: async ({commit}, payload) => {
    commit(CHANGE_CONTENT_LOADING, true);
    try {
      const response = await $http.get(`v1/pages/show?slug=${payload}`);
      commit(SET_PAGE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_CONTENT_LOADING, false);
    }
  },
  [GET_BLOG]: async ({commit}, payload) => {
    commit(CHANGE_CONTENT_LOADING, true);
    try {
      const response = await $http.get(`v1/blogs/show?slug=${payload}`);
      commit(SET_BLOG, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_CONTENT_LOADING, false);
    }
  },
};

const mutations = {
  [SET_CONTENT](state, obj) {
    state.content = obj;
  },
  [SET_MAGAZINES](state, obj) {
    state.magazines = obj;
  },
  [SET_TRUNK](state, obj) {
    state.trunk = obj;
  },
  [SET_TRUNK_SHOW](state, obj) {
    state.trunkShow = obj;
  },
  [CHANGE_PAGE_SKIP](state) {
    state.limit += 9;
    // state.skip += 9;
  },
  [RESET_SKIP](state) {
    state.limit = 9;
    state.skip = 0;
  },
  [SET_CONTACT](state, obj) {
    state.contact = obj;
  },
  [SET_BANNER_CONTENT](state, obj) {
    state.bannerContent = obj;
  },
  [SET_PAGE](state, obj) {
    state.page = obj;
  },
  [SET_BLOG](state, obj) {
    state.blog = obj;
  },
  [SET_PAGE_CONTENT](state, obj) {
    state.pageContent = obj;
  },
  [CHANGE_CONTENT_LOADING](state, status) {
    state.contentLoading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};